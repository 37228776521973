@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #FFF;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.container-blue{
  background-color: #192e57;
  color: white;
  border-style: hidden;
  border-radius: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}
